// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["percentage", "calculations"];

  #placeable = true;
  #url = false;
  #year = false;
  #percentage = false;

  connect() {
    this.#url = this.element.getAttribute("data-calculations-url");
    this.#year = document.querySelector("midwest-input[name='pricing[year]']").value;
    if (this.percentageTarget.value !== "undefined") {
      this.#percentage = this.percentageTarget.value;
    }
    this.percentageTarget.addEventListener("update", (e) => this.setPercentage(e));

    this.requestPovertyLines();
  }

  async setPercentage(e) {
    if (e.detail !== "undefined") {
      this.#percentage = e.detail;
      this.#year = document.querySelector("midwest-input[name='pricing[year]']").value;
      await this.requestPovertyLines();
    }
  }

  async requestPovertyLines() {
    if (!!this.#year && !!this.#percentage) {
      if (this.#placeable) {
        this.#placeable = false;
        const content = await (await fetch(this.#url + `?year=${this.#year}&percentage=${this.#percentage}`)).text();
        const html = new DOMParser().parseFromString(content, "text/xml");
        const result = html.querySelector(".result");

        this.calculationsTarget.innerHTML = result.outerHTML;
        this.makePlaceable();
      }
    }
  }

  makePlaceable() {
    this.#placeable = true;
  }
}
