// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["collapsed", "expanded", "label", "cancel"];

  #primary = false;
  #open = false;
  #originalLabel = "";
  #label = "";
  #wizardButtons;

  connect() {
    this.#wizardButtons = document.querySelectorAll("#wizardSteps midwest-button");
    this.#primary = this.element.classList.contains("primary");
    this.#originalLabel = this.labelTarget.innerText.replace(/[\n\r]+|[\s]{2,}/g, " ");
    this.expandedTarget.addEventListener("submitted", () => this.onSubmit());
    this.expandedTarget.addEventListener("fastUpdates", (e) => this.updateCard(e));
  }

  toggle() {
    this.#open = !this.#open;
    this.collapsedTarget.hidden = this.#open;
    this.expandedTarget.hidden = !this.#open;

    this.#wizardButtons.forEach((el) => (el.disabled = this.#open));

    if (this.#open) {
      setTimeout(() => {
        this.expandedTarget.querySelector("midwest-input").setFocus();
      }, 350);
    } else {
      this.fireCloseNewFormEvent();
    }
  }

  fireCloseNewFormEvent() {
    const event = new CustomEvent("close-new-form", { bubbles: true });
    this.element.dispatchEvent(event);
  }

  setLabel(e) {
    const email = e.detail.results.find((e) => e.name === "user[email]");
    const firstName = e.detail.results.find((e) => e.name === "user[first_name]");
    const lastName = e.detail.results.find((e) => e.name === "user[last_name]");

    if (email.valid && firstName.valid && lastName.valid) {
      this.#label = `${this.#primary ? "Primary" : "Additional"} Caregiver: ${firstName.value} ${lastName.value} • ${email.value}`;
    } else {
      this.#label = this.#originalLabel;
    }

    this.labelTarget.innerText = this.#label;
  }

  updateCard(e) {
    this.setLabel(e);
  }
}
