// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["signInCard", "signUpCard", "signInButton", "signUpButton"];

  flip_sign_in() {
    this.signInCardTarget.flip_card();
    this.signUpCardTarget.closest("midwest-form").hidden = true;
    this.element.classList.add("max-w-2xl");
    this.signInCardTarget.querySelector("midwest-input").setFocus();
  }

  flip_sign_up() {
    this.signUpCardTarget.flip_card();
    this.signInCardTarget.closest("midwest-form").hidden = true;
    this.element.classList.add("max-w-2xl");
    this.signUpCardTarget.querySelector("midwest-input").setFocus();
  }

  reset() {
    if (this.signInCardTarget.flipped) {
      this.signInCardTarget.flip_card();
    }

    if (this.signUpCardTarget.flipped) {
      this.signUpCardTarget.flip_card();
    }

    this.element.classList.remove("max-w-2xl");
    this.signInCardTarget.closest("midwest-form").hidden = false;
    this.signUpCardTarget.closest("midwest-form").hidden = false;
  }
}
