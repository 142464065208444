// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="select">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('blur', () => {
      this.element.validate();
    });

    this.element.addEventListener('update', () => {
      setTimeout(() => this.element.validate(), 10);
    });
  }
}
