// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["collapsed", "expanded", "label", "preferences"];

  #open = false;
  #originalLabel = "";
  #label = "";
  #url = false;
  #placeable = false;
  #level = undefined;
  #instrument = undefined;
  #wizardButtons;

  connect() {

    this.#wizardButtons = document.querySelectorAll("#wizardSteps midwest-button");
    this.#url = this.element.getAttribute("data-class-options-url");
    this.#originalLabel = this.labelTarget.innerText.replace(/[\n\r]+|[\s]{2,}/g, " ").trim();
    this.expandedTarget.addEventListener("submitted", () => this.onSubmit());
    this.expandedTarget.addEventListener("fastUpdates", (e) => this.updateCard(e));

    this.makePlaceable();
  }

  toggle() {
    this.#open = !this.#open;
    this.collapsedTarget.hidden = this.#open;
    this.expandedTarget.hidden = !this.#open;
    this.#wizardButtons.forEach((el) => (el.disabled = this.#open));

    if (this.#open) {
      setTimeout(() => {
        this.expandedTarget.querySelector("midwest-input").setFocus();
      }, 350);
    } else {
      this.fireCloseNewFormEvent();
    }
  }

  fireCloseNewFormEvent() {
    const event = new CustomEvent("close-new-form", { bubbles: true });
    this.element.dispatchEvent(event);
  }

  setLabel(e) {
    const firstName = e.detail.results.find((e) => e.name === "student[first_name]");
    const lastName = e.detail.results.find((e) => e.name === "student[last_name]");
    const nickname = e.detail.results.find((e) => e.name === "student[nickname]");
    const nicknameString = nickname?.valid && !!nickname.value ? `(${nickname.value})` : "";

    if (firstName?.valid && lastName?.valid) {
      this.#label = `Student: ${firstName.value} ${lastName.value} ${nicknameString}`;
    } else {
      this.#label = this.#originalLabel;
    }

    this.labelTarget.innerText = this.#label;
  }

  updateCard(e) {
    this.setLabel(e);
    this.setPreferredClasses(e);
  }

  async setPreferredClasses(e) {
    const instrument = e.detail.results.find((e) => e.name === "student[instrument_type]");
    const level = e.detail.results.find((e) => e.name === "student[level]");

    if (level?.valid && instrument?.valid) {
      await this.requestPreferredClasses(level.value, instrument.value);
    }
  }

  async requestPreferredClasses(level, instrument) {
    if (this.#level !== level || this.#instrument !== instrument) {
      this.#level = level;
      this.#instrument = instrument;

      if (this.#placeable) {
        this.#placeable = false;
        const content = await (await fetch(this.#url + `?level=${this.#level}&instrument_type=${this.#instrument}`)).text();
        const html = new DOMParser().parseFromString(content, "text/xml");
        const grid = html.querySelector(".result");

        this.preferencesTarget.innerHTML = grid.outerHTML;
        this.makePlaceable();
      }
    }
  }

  makePlaceable() {
    setTimeout(() => {
      this.#placeable = true;
    }, 2000);
  }
}
