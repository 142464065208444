// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ["form", "button", "actionables"];

  #open = false;

  connect() {
    this.toggle = debounce(this.toggle, 4).bind(this);
    this.element.addEventListener("close-new-form", () => {
      this.toggle();
    });
  }

  toggle() {
    this.#open = !this.#open;
    this.element.querySelector("midwest-button.add").disabled = !this.#open;

    console.log(this.#open);

    if (this.#open) {
      this.formTarget.removeAttribute("hidden");
      this.element.querySelector("midwest-button.edit").click();
    } else {
      this.formTarget.setAttribute("hidden", true);
    }

    if (this.actionablesTarget) {
      this.actionablesTarget.hidden = this.#open;
      this.actionablesTarget.querySelectorAll("midwest-button").forEach((button) => {
        button.disabled = this.#open;
      });
    }
  }
}
