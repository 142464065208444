// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["link", "timeRemaining"];

  #time = 10; // 10 seconds as a default
  #intervalId;

  connect() {
    this.#time = parseInt(this.timeRemainingTarget.dataset.time);
    this.updateContent();
    this.startTimer();
  }

  startTimer() {
    // Fire the interval every second
    this.#intervalId = setInterval(this.updateContent.bind(this), 1000);
  }

  updateContent() {
    this.timeRemainingTarget.innerText = this.#time--;

    if (this.#time < 0) {
      clearInterval(this.#intervalId);
    }

    if (this.#time <= 3) {
      this.linkTarget.fireClick();
    }
  }
}
